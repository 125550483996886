namespace Notifications
{
    export const showAlert = (options: AlertOptions) =>
    {
        new AlertNotification(options).show();
    }

    export const showStatus = (options: NotificationOptions) =>
    {
        new StatusNotification(options).show();
    }

    export const clearAlerts = () =>
    {
        getJQuery({ containerFor: "alertNotifications" }).html('');
    }

    export interface NotificationOptions
    {
        header: string,
        message: string,
    }

    abstract class Notification
    {
        protected get container(): JQuery
        {
            return getJQuery({ containerFor: "notifications" });
        }

        protected parse(templateName: string, value: any): string
        {
            return Templates.parse(this.container.getJQuery({ containerFor: templateName }), value);
        }
    }

    class StatusNotification extends Notification
    {
        private options: NotificationOptions;

        constructor(options: NotificationOptions)
        {
            super();
            this.options = options;
        }

        private templateName: string = "templateStatus";

        show(): void
        {
            const html = this.parse(this.templateName, { header: this.options.header, message: this.options.message });

            const $notificationContainer = this.container.getJQuery({ containerFor: "statusNotifications" });
            const $div = $(html).appendTo($notificationContainer);

            $div.toast('show');
            $div.on('hidden.bs.toast', () => $div.remove());

            positionToastsAtTop();
            $(window).on("scroll resize", positionToastsAtTop);

            function positionToastsAtTop()
            {
                const offset = 15;
                $notificationContainer.css({ "top": $(window).scrollTop() + offset, "right": offset, "position": "absolute" });
            }
        }
    }

    export interface AlertOptions extends NotificationOptions
    {
        type?: AlertType,
        append?: boolean,
    }

    enum AlertType
    {
        success = "success",
        warning = "warning",
        danger = "danger",
        info = "info"
    }

    class AlertNotification extends Notification
    {
        private templateName: string = "templateAlert";

        private options: AlertOptions;

        constructor(options: AlertOptions)
        {
            super();
            this.options = options;
        }

        show(): void
        {
            this.options.type = this.options.type || AlertType.warning;

            const html = this.parse(this.templateName, { header: this.options.header, message: this.options.message, alertType: this.options.type });

            const $alertNotifications = getJQuery({ containerFor: "alertNotifications" });

            if (this.options.append)
            {
                $alertNotifications.append(html);
            }
            else
            {
                $alertNotifications.prepend(html);
            }

            $("html, body").animate({ scrollTop: 0 }, 100);
        }
    }
}